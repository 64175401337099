<template>
    <div class="NotFound">
        <div class="container">
          <h1>{{$trans('OOPS!')}}</h1>
          <div class="title">{{$trans("404 - The page can't be found")}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>